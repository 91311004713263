<template >
  <div 	    id="presenceReport"
			class="presenceReport card"
     style="height: calc(100vh - 143px)">
    <DataTable
	    scrollHeight="flex"
	    :scrollable="true"
	    :loading="loading"
	    responsiveLayout="stack"
	    breakpoint="960px"
	    @cell-edit-complete="onCellEditComplete"
	    :value="presencesSort"
	    class=" p-datatable-sm p-datatable-striped"
	    style="text-align: start !important;"
    >
      <template #header>
        <div class="p-grid p-jc-center">

          <Divider
			class="p-d-none p-d-md-flex"
			align="center"
			type="dashed"
		>
            <h2 ><b > {{ hebrewDate }}</b ></h2 >
          </Divider >

		  <div class="p-grid p-jc-center" v-if="!isUnlockMonth" style="color: red;
		  text-align: center">
			 <h5><b > {{ $t('presenceReport.locked') }}</b ></h5 >
		  </div>
        </div >
        <div class="p-grid ">
          <div
			:class="{'p-grid p-col-6  p-md-3 p-lg-4   p-jc-center': canPostAll,
                     'p-grid p-col-12  p-md-6 p-lg-12   p-jc-center':
                     !canPostAll}"
		>
            <Divider
			  align="center"
			  type="dashed"
			  class="p-col-12  "
		  >
              <b >{{ $t('presenceReport.guests') }}</b >
            </Divider >

            <InputNumber
			  :disabled="!isUnlockMonth"
			  id="guests"
			  decrementButtonClass="p-button-secondary"
			  incrementButtonClass="p-button-secondary"
			  incrementButtonIcon="pi pi-plus"
			  decrementButtonIcon="pi pi-minus"
			  :min="0"
			  :class="{'p-invalid': guestsCount === null}"
			  class="p-ml-3 p-mr-3"
			  :placeholder="$t('presenceReport.guestsRequest')"
			  v-model="guestsCount"
			  mode="decimal"
			  @input="postGuestCount"
		  />
          </div >
          <div
			class="p-grid p-col-6 p-md-8 p-lg-8 box p-d-flex p-jc-center"
		>
            <Divider
			  v-if="canPostAll"
			  align="center"
			  type="dashed"
			  class="p-col-12  "
		  >
              <b > {{ $t(`presenceReport.allPresence`, {amountPerson:
			   presences.length, amountAttendance: amountAttendance})
			    }}</b >
            </Divider >
            <div
			  v-if="canPostAll"
			  class=""
		  >
              <div >
                <Button
				 :disabled="guestsCount === null || !isUnlockMonth"
				 icon="pi pi-angle-down"
				 iconPos="right"
				 type="button"
				 class="p-button-secondary"
				 :label="$t(`presenceReport.allPresenceReport`)"
				 @click="toggle"
				 aria-haspopup="true"
				 aria-controls="overlay_menu"
			 />
                <Menu
				 id="overlay_menu"
				 ref="menu"
				 :model="buttonsAllPresence"
				 :popup="true"
			 />
              </div >
            </div >
          </div >
        </div >

      </template >
      <Column
		 field="first_name"
		 :header="$t(`presenceReport.name`)"
	 >
        <template #body="slotProps">
          {{ slotProps.data.last_name }}
          {{ slotProps.data.first_name }}

        </template >
      </Column >
      <Column
		 field="subtraction_allowed"
		 :header="$t(`presenceReport.subtraction_allowed`)"
	 >

      </Column >
      <Column
		 field="phone_number"
		 :header="$t(`presenceReport.phone_number`)"
	 >

      </Column >

      <Column
		 field="attendance_type"
		 :header="$t(`presenceReport.attendance_type`)"
	 >
        <template #body="slotProps">
		  <v-select
			  :items="presencesTypes"
			  item-text="value"
			  item-value="key"
			  v-model="slotProps.data.attendance"
			  :disabled="guestsCount === null || !isUnlockMonth"
			  placeholder="-"
			  return-object
			  @change="onCellEdit(slotProps)"


		  ></v-select>
<!--          <Dropdown-->
<!--			@before-hide="onCellEdit(slotProps)"-->
<!--			scrollHeight="300px"-->
<!--			style="text-align:right"-->
<!--			:disabled="guestsCount === null || !isUnlockMonth"-->
<!--			v-model="slotProps.data.attendance"-->
<!--			:options="presencesTypes"-->
<!--			optionLabel="value"-->
<!--			placeholder="-"-->
<!--		/>-->

        </template >
        <template #editor="slotProps">
          <!--          <template #editor="slotProps">-->
		<!--          {{ getPresenceDisplay(slotProps.data.attendance_type, slotProps.data.minutes_late) }}-->
          <Dropdown
			@before-hide="onCellEdit(slotProps)"
			scrollHeight="300px"

			:disabled="guestsCount === null || !isUnlockMonth"
			v-model="slotProps.data.attendance"
			:options="presencesTypes"
			optionLabel="value"
			placeholder="-"
		/>

        </template >
      </Column >

    </DataTable >

  </div>
</template >

<script >
import {mapState, mapActions, mapGetters} from "vuex";
import constants from '@/constants'
import {
  getPresences,
  getPresencesTypes,
  postPresence,
  postPresences,
  postGuestCount,
  expansionPresence, getPresencesValue
} from
      "./presenceReport";
import {dateToFullHebrew} from "@/utils/dateFormat.js";
import {canPostAll} from "@/utils/permissions.js";
import Vue from "vue";
import {sortByLastName} from "@/utils/sorts";

export default {
  name: "presenceReport",
  props: {
    locks: Object,
    date: Date,
    colel_id: String
  },
  data() {
    return {
      loading: true,
      presencesOriginal: [],
      buttonsAllPresence: [
        {
          label: this.$t(`presenceReport.present`),
          icon: 'pi pi-check-circle',
          command: () => this.postAll(1)
        },
        {
          label: this.$t(`presenceReport.miss`),
          icon: 'pi pi-times-circle',
          command: () => this.postAll(2)
        },
        {
          label: this.$t(`presenceReport.allowed`),
          icon: 'pi pi-ticket',
          command: () => this.postAll(3)
        },
        {
          label: this.$t(`presenceReport.holiday`),
          icon: 'pi pi-sun',
          command: () => this.postAll(4)
        },
	   {
          label: this.$t(`presenceReport.empty`),
          icon: 'pi pi-circle',
          command: () => this.postAll(5)
        }
      ],

      presences: [],
      is_manager_lock: null,
      is_owner_lock: null,
      guestsCount: null,
      guestsCountOriginal: null,
      presencesTypes: null
    };
  },
  mounted() {
    this.getPresences(false)
    this.presencesTypes = getPresencesTypes()
  },
  computed: {
    presencesSort(){
      return this.presences.slice().sort((curr, next) => sortByLastName(curr,next))
    },
    amountAttendance() {
      return this.presences.filter(p => p.attendance_type !== 5).length
    },
    canPostAll(){
      if (this.isAdmin){
        return true
      }
      return this.colel.colel_details.settings.multiple_update
    },
    heightDiv(){
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      console.log(vh)
      return  `${vh - (vh/2.4)+40}px`
    },
    isUnlockMonth() {
      return this.isAdmin
		? !this.is_manager_lock
		: !(this.is_owner_lock || this.is_manager_lock)
    },
    ...mapState(["user","colel"]),
    ...mapGetters(["isAdmin"]),
    hebrewDate() {
      return dateToFullHebrew(this.date)
    },

  },
  watch: {
    colel(){
      this.presencesTypes = getPresencesTypes()
    },
    date() {
      this.getPresences(true)
      // this.getGuestCount()
    },
    colel_id() {
      this.getPresences(true)
      // this.getGuestCount()
    },
    locks(newLock, oldLock){
        this.is_manager_lock = newLock.is_manager_lock
        this.is_owner_lock = newLock.is_owner_lock
    }
  },
  components: {
    // personTable,
  },
  methods: {

    change(e){
      console.log("change", e)
    }, hide(e){
      console.log("hide", e)
    },
    validateNotSaturdayReport() {
      if (new Date(this.date).getDay() === 6) {
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t('errors.attendanceReportError'),
                          detail:
                              this.$t('errors.saturdayReportError'),
                          life: constants.LIFE_TOAST
                        });
        return false;
      }
      return true;
    },
    presenceForAll(type) {
      this.postAll(type)

    },
    toggle(event) {
      event.relativeAlign = true
      this.$refs.menu.toggle(event);
    },
    async onCellEdit(props) {
      console.log("props", props)
      try {
        await this.post(JSON.parse(JSON.stringify(props.data)))
        Vue.set(this.presencesOriginal, props.index,
                JSON.parse(JSON.stringify(this.presences[props.index])));

      } catch (e) {
        Vue.set(this.presences, props.index,
                JSON.parse(
                    JSON.stringify(this.presencesOriginal[props.index])));
      }

    },

    onCellEditComplete(event) {
    },

    async getPresences(needToLoading = true) {
      try {
        if (needToLoading) {
          this.loading = true
        }
        const {data} = await getPresences(this.colel_id, this.date)
        this.is_manager_lock = data.is_manager_lock
        this.is_owner_lock = data.is_owner_lock
        this.loading = false

        expansionPresence(data.attendance)
        this.presences = JSON.parse(JSON.stringify(data.attendance))
        this.presencesOriginal = JSON.parse(JSON.stringify(data.attendance));
        this.guestsCount = JSON.parse(JSON.stringify(data.guests_number))
        this.guestsCountOriginal =
            JSON.parse(JSON.stringify(data.guests_number));
      } catch (e) {
        console.log(e)
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t(
                              'presenceReport.getPresencesErrorTitle'),
                          detail:
                              this.$t('presenceReport.getPresencesErrorDetail'),
                          life: constants.LIFE_TOAST
                        });
      }
    },

    async postAll(type) {
      try {
        // if (!this.canPost()) {
        //   throw "lock"
        // }
        const data = await postPresences(this.colel_id,
                                         this.date, type)
        this.$emit("updatePresence")
        this.getPresences(false)

        this.$toast.add({
                          severity: 'success',
                          summary:
                              this.$t('presenceReport.postAllSuccessTitle'),
                          detail:
                              this.$t('presenceReport.postAllSuccessDetail',
							   {type: getPresencesValue(type)}),
                          life: constants.LIFE_TOAST
                        });
      } catch (e) {
        if (e === "lock") {
          this.$toast.add({
                            severity: 'error',
                            summary:
                                this.$t('presenceReport.lockPostAllErrorTitle'),
                            detail:
                                this.$t(
                                    'presenceReport.lockPostAllErrorDetail'),
                            life: constants.LIFE_TOAST
                          });
        } else {
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t(
                                'presenceReport.postAllErrorTitle'),
                            detail:
                                this.$t('presenceReport.postAllErrorDetail'),
                            life: constants.LIFE_TOAST
                          });
        }
      }
    },
    async post(presence) {
      try {
        // if (!this.canPost()) {
        //   throw "lock"
        // }
        if (this.validateNotSaturdayReport()) {

          await postPresence(presence, this.colel_id, this.date);
          const {data} = await getPresences(this.colel_id, this.date)
          const subtraction_allowed = data.attendance.find(presenceRes =>
										   presenceRes.id === presence.id).subtraction_allowed
		   this.presencesSort.find(p => p.id === presence.id).subtraction_allowed = subtraction_allowed
          // await this.getPresences(false)
          this.$emit("updatePresence")
        }
      } catch (e) {
        console.log(e)
        if (e === "lock") {
          this.$toast.add({
                            severity: 'error',
                            summary:
                                this.$t('presenceReport.lockPostAllErrorTitle'),
                            detail:
                                this.$t(
                                    'presenceReport.lockPostAllErrorDetail'),
                            life: constants.LIFE_TOAST
                          });
        } else {
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t('presenceReport.postErrorTitle'),
                            detail:
                                this.$t('presenceReport.postErrorDetail'),
                            life: constants.LIFE_TOAST
                          });
        }
      }
    },
    async postGuestCount(event) {
      if (!this.validateNotSaturdayReport()) {
        this.guestsCount = JSON.parse(JSON.stringify(this.guestsCountOriginal));
      } else if (event >= 0) {
        try {
          await postGuestCount(this.colel_id, this.date, event)
          this.$emit("updatePresence")
        } catch {
          this.guestsCount =
              JSON.parse(JSON.stringify(this.guestsCountOriginal));
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t(
                                'presenceReport.postGuestCountErrorTitle'),
                            detail:
                                this.$t(
                                    'presenceReport.postGuestCountErrorDetail'),
                            life: constants.LIFE_TOAST
                          });
        }
      }
    }
  }
};
</script >

<style
	scoped
>

>>> .p-menu.p-menu-overlay {
    width: auto;
}
>>> .p-datatable .p-datatable-tbody > tr > td {
    text-align: right;
}

>>> .p-menu .p-menuitem-link .p-menuitem-text {
    padding-right: 10px;
}

>>> .p-button .p-button-icon-right {
    margin-right: 0.5rem;
}

>>> .p-menu.p-menu-overlay {
    /*background: aqua;*/
    top: none !important;
    left: none !important;
}

>>> .p-inputtext {
    /*width: -webkit-fill-available !important;*/
    width: 100% !important;
}

>>> .p-buttonset .p-button:last-of-type {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

>>> .p-button .p-button-icon-left {
    margin-right: 0rem;
    margin-left: 0.5rem;
}

>>> .p-datatable-wrapper .p-datatable-striped {
    margin-right: 0rem;
    margin-left: 0.5rem;
}

>>> .p-buttonset .p-button:first-of-type {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}
</style >
