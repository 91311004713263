export const sortByLastName = (curr, next, reverse = false) => {
  if (curr.last_name < next.last_name) {
    if (reverse) {
      return 1;
    } else {
      return -1;
    }
  }
  if (curr.last_name > next.last_name) {
    if (reverse) {
      return -1;
    } else {
      return 1;
    }
  }
  return 0;
}

export const sortByLeaving = (curr, next, reverse = false) => {
  if (curr.leaving_date !== null && next.leaving_date === null) {
    if (reverse) {
      return -1;
    } else {
      return 1;
    }
  } else {
    if (reverse) {
      return 1;
    } else {
      return -1;
    }
  }
}
