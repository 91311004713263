<template >
  <div class="presence">

        <Toast position="bottom-center" group="bc">
            <template #message="slotProps">
                <div class="flex flex-column">
                    <div class="text-center">
                        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                        <h4>{{slotProps.message.summary}}</h4>
                        <p>{{slotProps.message.detail}}</p>
                    </div>
                    <div class="p-d-flex ">
                        <div  >
                            <Button class="p-m-2 p-button-danger"
							 label="כל הכולים"
							 @click="agreePaste(true)"></Button>
                        </div>
                        <div >
                            <Button class="p-m-2 p-button-success"
							 label="רק לכולל הנוכחי"
							 @click="agreePaste(false)"></Button>
                        </div>
				    <div >
                            <Button class="p-m-2 p-button-secondary"
							 label="ביטול העתקה"
							 @click="cancelPaste"></Button>
                        </div>
                    </div>
                </div>
            </template>
        </Toast>
  <div
	  class='p-grid  p-flex-row-reverse'
	  v-bind:class="{ 'p-flex-row-reverse': (screenSize !== 'xs'  && screenSize !== 'sm') }"
  >


<div
	v-if="colel"
	class="p-d-flex p-col-12 p-md-6 p-lg-6  p-jc-center p-pt-4 p-p-lg-5"
>

    <div >
      <colel-calendar
		 v-if="!copyMode"
		 :disabledDays="[6]"
		 :isCopyMode="copyMode"
		 isPresentMode
		 :yearNavigator="true"
		 yearRange="2000:2030"
		 :monthNavigator="true"
		 :manualInput="false"
		 :isAdmin="isAdmin"
		 v-on:lock="lockColel"
		 v-on:locksUpdate="locksUpdate"
		 :showButtonBar="true"
		 :update="calendarUpdate"
		 :colel_id="colel.colel.id"
		 :dateFormat="dateformat"
		 :touchUI="screenSize === 'xs' || screenSize === 'sm'"
		 v-model="selectedDate"
		 v-on:copy-toggle="copyToggle"
		 :inline="screenSize !== 'xs'  && screenSize !== 'sm'"
		 :showWeek="false"
	 />
	      <colel-calendar
			 v-else
			 :isCopyMode="copyMode"
			 selectionMode="range"
			 :disabledDays="[6]"
			 :yearNavigator="true"
			 yearRange="2000:2030"
			 :monthNavigator="true"
			 :manualInput="false"
			 :isAdmin="isAdmin"
			 :showButtonBar="true"
			 :update="calendarUpdate"
			 :colel_id="colel.colel.id"
			 :dateFormat="dateformat"
			 :touchUI="screenSize === 'xs' || screenSize === 'sm'"
			 v-model="selectedDateToCopy"
			 :inline="screenSize !== 'xs'  && screenSize !== 'sm'"
			 :showWeek="false"
			 :isCopyNow="isCopyNow"
			 :titleCopy="titleCopy"
			 v-on:copy-toggle="copyToggle"

			 v-on:copy-range="copyRange"
			 v-on:paste-range="pasteRange"

		 />
    </div >
    </div >

    <div class="p-col-12 p-md-6 p-lg-6 p-jc-center p-p-4  p-pt-lg-5">
	      <BlockUI :blocked="copyMode" >
      <presenceReport
		 ref="presenceReport"

		 :locks="locks"
		 v-on:updatePresence="updatePresence"
		 :date="selectedDate"
		 :colel_id="colel.colel.id"
	 />
			   </BlockUI >
    </div >
  </div >

  </div >
</template >

<script >
import {minMonthPresence} from "@/utils/permissions.js";
import {
  dateToDayAndMonthAndYear,
  dateToFullHebrew
} from "@/utils/dateFormat.js";
import {mapState, mapActions, mapGetters} from "vuex";
import presenceReport from "./presenceReport/presenceReport.vue";
import {lockColel, pasteDateRange} from "@/views/presence/presence";
import constants from "@/constants";

export default {
  name: "presence",

  data() {
    return {
      selectedDate: new Date(),
      selectedDateToCopy: null,
      selectedDateToCopyFinal: null,
      calendarUpdate: 0,
      isCopyNow: false,
      copyMode: false,
      locks: null
    };
  },
  watch: {
    colel(newValue, oldValue) {
      this.calendarUpdate++
    }
  },
  computed: {
    titleCopy() {
      let title = ''
	 if (this.selectedDateToCopy !== null) {
        if (this.selectedDateToCopy?.[0] !== null) {
          if (this.isCopyNow){
            title += 'העתק '
          } else {
            title += 'הדבק '
          }
          title +=
		    ` מ ${dateToFullHebrew(this.selectedDateToCopy?.[0])} `
        }
        if (this.selectedDateToCopy?.[1] !== null) {
          title += `\n עד ${dateToFullHebrew(this.selectedDateToCopy?.[1])}`
        }
      }

      return title
    },
    ...
        mapState(["user", "colel"]),
    ...
        mapGetters(["isAdmin", "colelId"]),
    screenSize() {
      return this.$vuetify.breakpoint.name
    }
    ,
    minDate() {
      let date = new Date();
      date.setMonth(date.getMonth() - minMonthPresence());
      return date
    }
    ,
    dateformat() {
      return dateToFullHebrew(this.selectedDate);
    }
  },
  components: {
    presenceReport
    // personTable,
  }
  ,
  methods: {
    copyRange() {
      this.selectedDateToCopyFinal =
          this.selectedDateToCopy
      if (this.selectedDateToCopy == null
          || this.selectedDateToCopy[0]
          == null) {
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t('errors.rangeToCopy'),
                          life: constants.LIFE_TOAST
                        })
      } else {
        if (this.selectedDateToCopy[1] == null) {
          this.selectedDateToCopy[1] = this.selectedDateToCopy[0]
        }
        this.$toast.add({
                          severity: 'success',
                          summary:
                              this.$t('success.rangeToCopyTitle'),
                          detail:
                              this.$t('success.rangeToCopy', {
                                startDate:
                                    dateToFullHebrew(
                                        this.selectedDateToCopy[0]),
                                endDate:
                                    dateToFullHebrew(
                                        this.selectedDateToCopy[1])
                              }),
                          life: constants.LIFE_TOAST + 400000

                        })

        this.isCopyNow = !this.isCopyNow
        this.selectedDateToCopy = null

      }
    }
    ,
    async pasteRange() {
      // this.selectedDateToCopyFinal = JSON.parse(JSON.stringify(this.selectedDateToCopy))
      this.$toast.add({severity: 'warn', summary:
		  this.$t('presenceReport.askAllColel', {colelId: this.colelId }), group: 'bc'});
    },
    cancelPaste(){
      this.$toast.removeGroup('bc');
      this.selectedDateToCopy = null
      this.selectedDateToCopyFinal = null
	 // this.copyToggle()
      this.isCopyNow = !this.isCopyNow

      this.calendarUpdate++
    },
    async agreePaste(allColels = false){
      this.$toast.removeGroup('bc');
      if (this.selectedDateToCopy == null || this.selectedDateToCopy[0]
          == null) {
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t('errors.rangeToPaste'),
                          life: constants.LIFE_TOAST
                        })
      } else {
        if (this.selectedDateToCopy[1] == null) {
          this.selectedDateToCopy[1] = this.selectedDateToCopy[0]
        }
        this.$toast.add({
                          severity: 'success',
                          summary: this.$t('success.rangeToPasteTitle'),
                          detail:
                              this.$t('success.rangeToCopy', {
                                startDate:
                                    dateToFullHebrew(
                                        this.selectedDateToCopy[0]),
                                endDate:
                                    dateToFullHebrew(
                                        this.selectedDateToCopy[1])
                              }),
                          life: constants.LIFE_TOAST + constants.LIFE_TOAST

                        })
        try {
          const startCopy = this.selectedDateToCopyFinal[0]
          const endCopy = this.selectedDateToCopyFinal[1]
          const startPaste = this.selectedDateToCopy[0]
          const endPaste = this.selectedDateToCopy[1]
          const colel = allColels ? null : this.colelId
          this.isCopyNow = !this.isCopyNow

          this.selectedDateToCopy = null
          this.selectedDateToCopyFinal = null

          await pasteDateRange(startCopy, endCopy, startPaste, endPaste, colel)
          this.calendarUpdate++

        } catch (e) {
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t('errors.pasteField'),
                            life: constants.LIFE_TOAST
                          })
        }
      }
    },
    copyToggle() {
      this.copyMode = !this.copyMode
      this.isCopyNow = !this.isCopyNow
      this.selectedDateToCopy = null
    }
    ,
    lockColel() {
      try {
        lockColel(this.colel.colel.id, this.isAdmin)

      } catch (e) {
        console.log(e)
      }
    }
    ,
    updatePresence() {
      this.calendarUpdate++
    }
    ,
    locksUpdate(locksObj) {
      // this.calendarUpdate++

      this.locks = locksObj
    }
  }
}
;
</script >

<style >

>>> .p-datepicker-touch-ui, .p-calendar .p-datepicker-touch-ui {
    z-index: 1017 !important;
}

.p-toast-detail {
    white-space: break-spaces;
}
</style >
