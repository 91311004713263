import axios from "axios";
import {dateToServer} from "@/utils/dateFormat";

export async function lockColel(colel_id, isAdmin) {
    if (isAdmin){

        await axios.patch(`/manager/attendance/lock`, colel_id);

    } else {
        await axios.post(`/attendance/lock`, colel_id);
    }
}

export async function pasteDateRange(startCopy, endCopy, startPaste, endPaste, colel_id) {
    const body = {
        "source_start_date": dateToServer(startCopy),
        "source_end_date": dateToServer(endCopy),
        "destination_start_date": dateToServer(startPaste)
    }
    
       return  await axios.post(`/manager/attendance/copy${colel_id ? `?colel_id=${colel_id}`: ``}`, body);
}

