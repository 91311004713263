import axios from "axios";
import store from '@/store'
import {dateToServer} from "@/utils/dateFormat";

function contractionPresence(p, colel_id, date) {
    p.colel_id = colel_id
    p.person_id = p.id
    p.date = date
    if (p.attendance.key !== 1 &&
        p.attendance.key !== 2 &&
        p.attendance.key !== 3 &&
        p.attendance.key !== 4 &&
        p.attendance.key !== 5
    ) {

        p.attendance_type = 1
        p.minutes_late = p.attendance.key
    } else {
        p.attendance_type = p.attendance.key
        delete p.minutes_late

    }
    delete p.attendance
    return p

}

export function expansionPresence(presences) {

    const types = getPresencesTypes()
    // const times = getPresencesTimesReceive()
    presences.forEach(p => {
        p.minutes_late = convertTime(p.minutes_late)
        p.attendance = types.find(t => t.key === p.attendance_type)
        if (p.attendance_type === 1) {
            p.attendance = types.find(t => t.key === p.minutes_late)
            if (p.attendance === undefined) {
                p.attendance = types.find(t => t.key === p.attendance_type)
            }
        }
    })

}

export async function getPresences(colel_id, date) {

    const addDate = new Date(date)
    // addDate.setDate(addDate.getDate() + 1)

    const a = await axios.get(`/attendance/${colel_id}/${dateToServer(addDate)}`)
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(a);
        }, 1000)


    });


}

export async function postGuestCount(colel_id, dateReceive, count) {
    const date = new Date(dateReceive)
    if (count >= 0) {
        await axios.post(`/colel/guests`, {colel_id, date: dateToServer(date), count});
    }
}

export async function postPresences(colel_id, date, attendance_type) {
    const addDate = new Date(date)
    return await axios.post(`/attendance/all`, {colel_id, date: dateToServer(addDate), attendance_type});
}

export async function postAllPersonsPresences(date, attendance_type) {
    // TODO
    // const addDate = new Date(date)//all
    return await axios.post(`/manager/attendance/all`, {date: dateToServer(date), attendance_type: attendance_type});
}

export async function postPresence(presence, colel_id, date, types) {

    const presenceContracted = contractionPresence(
        JSON.parse(JSON.stringify(presence)), colel_id, dateToServer(date))
    await axios.post(`/attendance/`, presenceContracted);
}

export function getPresencesValue(key) {
    return  getPresencesTypes().find(item => item.key === key).value
}
export function getPresencesTypes() {
    const regularPresences = [{"key": 1, "value": "נוכח"}, {"key": 2, "value": "חסר"}, {"key": 3, "value": "ח.מ."}, {
        "key": 4,
        "value": "חג"
    }, {"key": 5, "value": "-"}, {"key": "00:05", "value": 5}, {"key": "00:10", "value": 10}, {
        "key": "00:15",
        "value": 15
    }, {"key": "00:20", "value": 20}, {"key": "00:25", "value": 25}, {"key": "00:30", "value": 30}]
    const extraPresences = [{"key": "00:35", "value": 35},
        {"key": "00:40", "value": 40},
        {"key": "00:45", "value": 45},
        {"key": "00:50", "value": 50},
        {"key": "00:55", "value": 55},
        {"key": "00:60", "value": 60},
        {"key": "00:65", "value": 65},
        {"key": "00:70", "value": 70},
        {"key": "00:75", "value": 75},
        {"key": "00:80", "value": 80},
        {"key": "00:85", "value": 85},
        {"key": "00:90", "value": 90}]
    const special = `ירושלים - אחה"צ`
    return store.state?.colel?.colel?.id !== special ? regularPresences : [...regularPresences,...extraPresences]
    // return store.state.settings.attendanceTypes
}

function convertTime(time) {
   const times = [{key: "00:05", "value": 5}, {key: "00:10", "value": 10}, {
       key: "00:15",
       "value": 15
   }, {key: "00:20", "value": 20}, {key: "00:25", value: 25}, {key: "00:30", "value": 30}, {
       key: "0:35",
       "value": 35
   }, {key: "00:40", "value": 40}, {key: "00:45", "value": 45}, {key: "00:50", "value": 50}, {
       key: "00:55",
       "value": 55
   }, {key: "01:00", "value": 60}, {key: "01:00", "value": 65}, {key: "01:00", "value": 70}, {key: "01:00", "value": 75}, {key: "01:00", "value": 80}, {key: "01:00", "value": 85}, {key: "01:00", "value": 90}]
    return times.find(t => t.value === time)?.key
}
//
// export function getPresencesTimesReceive() {
//     return [{"key": "5", "value": 5}, {"key": "10", "value": 10}, {
//         "key": "15",
//         "value": 15
//     }, {"key": "20", "value": 20}, {"key": "25", "value": 25}, {"key": "30", "value": 30}, {
//         "key": "35",
//         "value": 35
//     }, {"key": "40", "value": 40}, {"key": "45", "value": 45}, {"key": "50", "value": 50}, {
//         "key": "55",
//         "value": 55
//     }, {"key": "60", "value": 60}]
//     // return store.state.settings.attendanceTypes
// }
